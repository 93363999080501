import request from '@/utils/request'

export function getZoneConfig (params) {
    return request({
        url: '/shd-operation/lrSaleSection/queryLrSaleSection',
        method: 'get',
        params
    })
}

export function saveZoneConfig (data) {
    return request({
        url: '/shd-operation/lrSaleSection/saveLrSaleSection',
        method: 'post',
        data
    })
}
